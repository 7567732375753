import React from "react";

import Layout from "../../../components/layout";
import { Seo } from "../../../components";
import { AboutProfileListV2 } from "../../../components/about-profile-list2";
import { useSearchProfile } from "../../../hooks/useSearchProfile";
import { SearchProfile } from "../../../components/search-profiles";
import { BACKEND_BASE_URL } from "../../../constant/constants";

const PeopleList = ({ loading, response, error }) => {
  const talonProps = useSearchProfile({
    data: response?.people,
  });
  const { filteredData, filter, data, searchLength } = talonProps;
  return (
    <>
      {data?.length > 0 && (
        <SearchProfile
          onChange={filter}
          actualData={data}
          searchLength={searchLength}
        />
      )}
      <div className="row mb-4">
        <AboutProfileListV2 loading={loading} response={filteredData} />
      </div>
    </>
  );
};

const PeopleByHead = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo title={serverData.name} />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">{serverData.name}</h3>
              <div className="row">
                <PeopleList response={serverData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const res = await fetch(
      BACKEND_BASE_URL + "people-by-head/" + context.params.slug
    );
    if (!res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: await res.json(),
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default PeopleByHead;
