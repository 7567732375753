import React, { useState } from "react";

const SearchProfile = props => {
  const { actualData, onChange, searchLength } = props;
  const [value, setValue] = useState("");
  let filterData = [];
  const handleChange = e => {
    setValue(e.target.value);
    filterData = [
      ...actualData?.filter(item =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      ),
    ];
    onChange(filterData);
  };

  const reset = () => {
    setValue("");
    onChange(actualData);
  };

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="search-profile">
          {value?.length > 0 && (
            <span className="search-profile__count">
              Search Results (<b>{searchLength}</b>)
            </span>
          )}
          <div className="search-profile__input">
            <input
              type="text"
              className="form-control"
              id="search"
              name="search"
              value={value}
              onChange={handleChange}
              onBlur={() => {}}
              placeholder="Search by name"
            />
            {value?.length > 0 && (
              <button className="search-profile__input-close" onClick={reset}>
                <span>X</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { SearchProfile };
