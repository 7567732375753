import React from "react";
import { Link } from "gatsby";
import Shimmer from "react-shimmer-effect";
import { isNotEmpty } from "../utils/utils";
import { dummyProfile } from "./common-images";

const AboutProfileListV2 = ({ loading, response, isProfileImage = true }) => {
	if (loading) {
		return (
			<Shimmer>
				<div className="about-profile-list-shimmer"></div>
			</Shimmer>
		);
	}

	if (isNotEmpty(response)) {
		return response?.map((item, index) => {
			return (
				<div
					className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4"
					key={`${item.uuid}${index}`}
				>
					<div className="about-profile-list h-100">
						{isProfileImage && (
							<Link
								className="about-profile-list__img-wrapper"
								to={`/people/${item?.uuid}`}
							>
								<img
									src={item?.image_url ? item?.image_url : dummyProfile}
									alt={item?.name}
								></img>
							</Link>
						)}
						<div className="about-profile-list__content p-4">
							<Link
								className="about-profile-list__content-title"
								to={`/people/${item?.uuid}`}
							>
								{item?.name}
							</Link>
							<p className="about-profile-list__content-address">
								{item?.address}
							</p>
							{item?.email_field && (
								<span className="d-flex align-items-center mb-1">
									<span className="icon ic-email me-2"></span>
									{item?.email_field?.split(",").map(email => {
										return (
											<a
												className="about-profile-list__content-email me-2"
												href={`mailto:${email}`}
												key={email}
											>
												{email}
											</a>
										);
									})}
								</span>
							)}
							{item?.mobile_field && (
								<span className="d-flex align-items-center">
									<span className="icon ic-mobile me-2"></span>
									<div className="d-flex flex-row">
										{item?.mobile_field?.split(",").map(mob => {
											return (
												<a
													className="about-profile-list__content-mobile me-2"
													href={`tel:${mob}`}
													key={mob}
												>
													{mob}
												</a>
											);
										})}
									</div>
								</span>
							)}
						</div>
					</div>
				</div>
			);
		});
	}
	return null;
};

export { AboutProfileListV2 };