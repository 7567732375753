import { useEffect, useState } from "react";

export const useSearchProfile = props => {
  const { data } = props;
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (data?.length) {
      setFilteredData(data);
    }
  }, [data]);

  const filter = d => {
    if (d.length) {
      setFilteredData(d);
    }
  };
  return {
    data,
    filter,
    filteredData,
    searchLength: filteredData?.length,
  };
};
